import React, { useState, useEffect } from 'react';
import { Drawer } from '../Drawer';

import './Main.css';
import classnames from 'classnames';

const CLASS_NAMES = [
    '',
    'red',
    'green',
    'black',
    'dark-green',
    'blue-zodiac',
    'purple',
    'yellow',
    'blue'
];

const randomRange = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
};

const Main = () => {

    const [isComplete, setIsComplete] = useState(false),
        [random, setRandom] = useState(null),
        [className, setClassName] = useState('');

    useEffect(() => {
        if (!isComplete) {
            setTimeout(() => {
                setIsComplete(true);
            }, 25000);
        }
    }, [isComplete]);

    useEffect(() => {
        if (!random) {
            const _random = randomRange(0, CLASS_NAMES.length);
            setRandom(_random);
        }
    }, [random, setRandom]);

    useEffect(() => {
        if (random >= 0) {
            setClassName(classnames(
                'main-page',
                CLASS_NAMES[random]
            ));
        }
    }, [random, setClassName]);

    return (
        <div className={className}>
            <Drawer isComplete={isComplete} setIsComplete={setIsComplete} />

            {
                isComplete &&
                <>
                    <h1>¡Felicidades!</h1>
                    <h2>A la mujer de la</h2>
                    <h3>mirada profunda</h3>
                    <h4>Te amo</h4>
                </>
            }
        </div>
    );
};

export default Main;